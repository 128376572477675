
























import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import PendingToolMove from '@/shared/modules/pending-tool-move/pending-tool-move.model'
import __ from '@/shared/helpers/__'
import ToolService from '@/shared/modules/tool/tool.service'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { AuthActions } from '@/shared/store/auth/auth.actions'
import _ from 'lodash'
import PendingMoveItemExpanded from '@/company/components/tools/PendingMoveItemExpanded.vue'
import { getBase64 } from '@/shared/helpers/get-base64.helper'

@Component({
  components: { DataTable },
  methods: { __ }
})
export default class PendingMoves extends Vue {
  @Prop() meta!: {
    pendingToolMoves: PendingToolMove[],
  }
  selected: Array<{
    item: PendingToolMove,
    data: {
      comment: string|null,
      photos: any[],
    }
  }> = []
  table: DataTableBase = null!

  created() {
    this.table = new DataTableBase()
      .setData(this.meta.pendingToolMoves)
      .setHasFilter(false)
      .setPaginationNotVisible(true)
      .setHeaders([
        new DataTableHeader()
          .setType(DataTableHeaderTypes.checkbox)
          .setKey('moved')
          .setValueParser((value: any, item: PendingToolMove) => this.selected.some((selectedItem: any) => selectedItem.item === item))
          .setMeta({
            action: (value: boolean, item: PendingToolMove, props: any) => {
              if (value) {
                this.selected.push({
                  item,
                  data: {
                    comment: '',
                    photos: [],
                  }
                })
              } else {
                this.selected = this.selected.filter((selectedItem: any) => selectedItem.item !== item)
              }

              props.expand(value)
            }
          })
          .setSortable(false),
        new DataTableHeader()
          .setKey('internal_no')
          .setEntryKey('internalNo')
          .setText(__('company.components.tools.pending-moves.headers.internal-no'))
          .setSortable(false),
        new DataTableHeader()
          .setKey('name')
          .setText(__('company.components.tools.pending-moves.headers.tool-name'))
          .setSortable(false),
        new DataTableHeader()
          .setKey('old_location')
          .setEntryKey('oldLocation')
          .setText(__('company.components.tools.pending-moves.headers.from'))
          .setSortable(false),
        new DataTableHeader()
          .setKey('new_location')
          .setEntryKey('newLocation')
          .setText(__('company.components.tools.pending-moves.headers.to'))
          .setSortable(false),
      ])
      .setExpandedItemComponent(PendingMoveItemExpanded, {
        updateItemComment: (item: PendingToolMove, value: string) => {
          const findIndex = this.selected.findIndex((selectedItem: any) => selectedItem.item === item)

          _.set(this.selected, `${ findIndex }.data.comment`, value)
        },
        updateItemPhotos: (item: PendingToolMove, value: any[]) => {
          const findIndex = this.selected.findIndex((selectedItem: any) => selectedItem.item === item)

          _.set(this.selected, `${ findIndex }.data.photos`, value)
        }
      })
  }

  async selectedToolsMove(type: string) {
    const selected = _.cloneDeep(this.selected)

    await Promise.all(this.selected.map(async (selectedItem: any, index: number) => {
      await Promise.all(selectedItem.data.photos.map(async (photo: any, photoIndex: number) => {
        const base64 = await getBase64(photo.file)
        _.set(selected, `${ index }.data.photos.${ photoIndex }.file_name`, photo.file.name)
        _.set(selected, `${ index }.data.photos.${ photoIndex }.file`, base64)
      }))
    }))

    const grouped = _.groupBy(selected, 'item.pendingMoveUuid')
    const data: any[] = [];

    Object.keys(grouped).forEach((key: string) => {
      data.push({
        pending_move_uuid: key,
        tool_data: grouped[key].map((groupedItem: any) => ({
          uuid: groupedItem.item.uuid,
          data: groupedItem.data,
        })),
      })
    })

    if (type === 'accept') {
      ToolService.acceptPendingMoves(this.company, data).then(() => this.$store.dispatch(AuthActions.me))
      return;
    }

    ToolService.declinePendingMoves(this.company,data).then(() => this.$store.dispatch(AuthActions.me))
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
