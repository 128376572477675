











import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'
import LoginLikeUserControl from '@/shared/components/layout/LoginLikeUserControl.vue'

@Component({
  methods: { __ }
})
export default class LoginLikeBar extends Vue {
  mounted() {
    this.setNewHeightOnSpacing.bind(this)
    this.setNewHeightOnSpacing()
    window.addEventListener('resize', this.setNewHeightOnSpacing, true)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.setNewHeightOnSpacing, true)
  }

  setNewHeightOnSpacing = () => {
    const spacing: any = this.$refs.spacing
    const alert: any = this.$refs.alert
    this.$store.dispatch(GlobalActions.setHeadersHeight, 55 + (alert ? alert.$el.clientHeight : 0))
    if (! spacing || ! alert) return
    spacing.style.height = `${ alert.$el.clientHeight }px`
    const nav: any = document.getElementsByClassName('nav')[0]
    if (! nav) return
    nav.style.height = `calc(100vh - 55px - ${ alert.$el.clientHeight }px)`
  }

  openControl(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: LoginLikeUserControl
    })
  }

  get user(): User | null {
    return this.$store.getters[AuthGetters.getUser]
  }
}
