















import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import User from "@/shared/modules/user/user.model";
import { AuthGetters } from "@/shared/store/auth/auth.getters";
import Header from "@/shared/components/layout/Header.vue";
import Navigation from "@/shared/components/layout/Navigation.vue";
import { GlobalGetters } from "@/shared/store/global/global.getters";
import { NavigationState } from "@/shared/store/global/global.state";
import NavigationItem from "@/shared/classes/layouts/navigation-item";
import { CompanyRoutes } from "@/shared/router/company";
import IUserItem from "@/shared/interfaces/admin/components/layout/user-item.interface";
import permissions from "@/shared/helpers/permissions.helper";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import LoginLikeBar from "@/company/components/layout/LoginLikeBar.vue";
import tokenHelper from "@/shared/helpers/token.helper";
import { RoleLevel } from "@/shared/modules/role/role.model";

@Component({
    components: {
      Navigation,
      Header,
      LoginLikeBar
    }
  })
  export default class CompanyLayout extends Vue {
    navigationItems: NavigationItem[] = [
      // new NavigationItem()
      //   .setTitle(__('company.layouts.default.navigation.dashboard'))
      //   .setIcon('mdi-view-dashboard')
      //   .setRoute(CompanyRoutes.dashboard),
      new NavigationItem()
        .setTitle(__('company.layouts.default.navigation.projects'))
        .setIcon('mdi-office-building')
        .setPermissions([
          permissions.company.projects.index,
          permissions.company.tasks.index,
          permissions.company.violations.index,
        ])
        .setChildOpen(CompanyRoutes.projectsIndex)
        .setPermissionsAtLeastOne(true)
        .setSubItems([
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.projects'))
            .setPermissions([ permissions.company.projects.index ])
            .setRoute(CompanyRoutes.projectsIndex),
          ! this.disabled ? new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.tasks'))
            .setPermissions([ permissions.company.tasks.index ])
            .setIsVisible(this.company?.isTimeAccountingVisible || this.company?.isToolsVisible)
            .setRoute(CompanyRoutes.tasksIndex) : new NavigationItem().setPermissions(['disabled-permission']),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.violations'))
            .setPermissions([ permissions.company.violations.index ])
            .setRoute(CompanyRoutes.violationsIndex),
        ]),
      new NavigationItem()
        .setTitle(__('company.layouts.default.navigation.checklists'))
        .setPermissions([ permissions.company.checklists.index ])
        .setIsVisible(this.company?.isTimeAccountingVisible || this.company?.isToolsVisible)
        .setRoute(CompanyRoutes.checklistsIndex)
        .setIcon('mdi-text-box-check-outline'),
      !this.disabled ?
        new NavigationItem()
          .setTitle(__('company.layouts.default.navigation.reports.submenu.reports'))
          .setPermissions([ permissions.company.reports.index ])
          .setIsVisible(this.company?.isTimeAccountingVisible || this.company?.isToolsVisible)
          .setIcon('mdi-file-document-outline')
          .setRoute(CompanyRoutes.reportsIndex) :
        new NavigationItem().setPermissions(['disabled-permission']),
      new NavigationItem()
        .setTitle(__('admin.layouts.default.navigation.courses'))
        .setIcon('mdi-school')
        .setIsVisible(true)
        .setSubItems([
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.assigned-courses'))
            .setRoute(CompanyRoutes.coursesIndex),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.course-statistics'))
            .setRoute(CompanyRoutes.coursesStatistics)
        ]),
      new NavigationItem()
        .setTitle(__('admin.layouts.default.navigation.hse'))
        .setIcon('mdi-hard-hat')
        .setIsVisible(!!this.company?.isHseVisible)
        .setSubItems([
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.hse-org-structure'))
            .setRoute(CompanyRoutes.orgStructureIndex),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.hse-comp-routines'))
            .setAllowedForRole([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin])
            .setRoute(CompanyRoutes.hseRoutinesIndexTabAssigned),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.hse-comp-routines'))
            .setAllowedForRole([RoleLevel.employee])
            .setRoute(CompanyRoutes.hseRoutinesIndexTabAssigned),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.hse-comp-risk-assessment'))
            .setAllowedForRole([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin])
            .setRoute(CompanyRoutes.riskAssessmentsIndexTabAssigned),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.hse-comp-risk-assessment'))
            .setAllowedForRole([RoleLevel.employee])
            .setRoute(CompanyRoutes.riskAssessmentsIndexTabAssigned),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.hse-violations'))
            .setRoute(CompanyRoutes.hseViolationsIndex),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.hse-handbook'))
            .setAllowedForRole([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin])
            .setRoute(CompanyRoutes.hseHandbookIndex),
        ]),
      new NavigationItem()
        .setTitle(__('company.layouts.default.navigation.employees'))
        .setIcon('mdi-account-supervisor')
        .setPermissions([ permissions.company.employees.index ])
        .setRoute(CompanyRoutes.employeesIndex),
      new NavigationItem()
        .setTitle(__('company.layouts.default.navigation.tools.title'))
        .setIcon('mdi-tools')
        .setPermissions([permissions.company.tools.index,])
        .setPermissionsAtLeastOne(true)
        .setIsVisible(!!this.company?.isToolsVisible)
        .setChildOpen(CompanyRoutes.toolsIndex)
        .setSubItems([
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.tools.submenu.overview'))
            .setRoute(CompanyRoutes.toolsIndex)
            .setPermissions([ permissions.company.tools.index ]),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.tools.submenu.move-tool'))
            .setRoute(CompanyRoutes.toolsMove)
            .setPermissions([ permissions.company.tools.move ]),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.tools.submenu.categories'))
            .setRoute(CompanyRoutes.toolCategoriesIndex)
            .setPermissions([ permissions.company.toolCategories.index, permissions.company.toolCategories.show, permissions.company.toolCategories.update ]),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.tools.submenu.new-location'))
            .setRoute(CompanyRoutes.toolLocationsIndex)
            .setPermissions([ permissions.company.toolLocations.index, permissions.company.toolLocations.show, permissions.company.toolLocations.update ]),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.tools.submenu.discarded-tools'))
            .setRoute(CompanyRoutes.toolsDiscarded)
            .setPermissions([ permissions.company.tools.destroy ])
        ]),
      new NavigationItem()
        .setTitle(__('company.layouts.default.navigation.settings.title'))
        .setIcon('mdi-cog')
        .setPermissions([
          permissions.company.info,
          permissions.company.taskTypes.index,
          permissions.company.permissions.index,
        ])
        .setPermissionsAtLeastOne(true)
        .setSubItems([
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.settings.submenu.company'))
            .setRoute(CompanyRoutes.info)
            .setPermissions([ permissions.company.info ]),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.settings.submenu.clients'))
            .setRoute(CompanyRoutes.clientsIndex)
            .setPermissions([ permissions.company.clients.index ]),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.settings.submenu.task-types'))
            .setRoute(CompanyRoutes.taskTypesIndex)
            .setPermissions([ permissions.company.taskTypes.index, permissions.company.taskTypes.update, permissions.company.taskTypes.create, permissions.company.taskTypes.delete ])
            .setIsVisible(this.company?.isTimeAccountingVisible || this.company?.isToolsVisible),
          new NavigationItem()
            .setTitle(__('company.layouts.default.navigation.settings.submenu.permissions-management'))
            .setRoute(CompanyRoutes.permissions)
            .setPermissions([ permissions.company.permissions.index ])
        ])
    ]
    userMenuItems: IUserItem[] | null = null

    mounted() {
      this.userMenuItems = [
        {
          icon: 'mdi-account',
          title: __('company.layouts.default.user-menu.profile'),
          action: () => this.$router.push({ name: CompanyRoutes.profile })
        },
        {
          icon: 'mdi-logout',
          title: __('company.layouts.default.user-menu.logout'),
          action: () => this.$router.push({ name: CompanyRoutes.logout }).catch(() => {
          })
        }
      ]
    }

    get disabled(): boolean {
      return this.company.uuid === '2242f02c-af85-47f7-ad0b-31fc26e1b502'
    }

    get inLoginLikeUser(): boolean {
      return tokenHelper.getOtherAccessTokens().length > 0
    }

    get company(): Company {
      return this.$store.getters[CompanyGetters.getCompany]
    }

    get user(): User {
      return this.$store.getters[AuthGetters.getUser]
    }

    get contentClass(): string {
      const navigationState = this.$store.getters[GlobalGetters.getNavigation]

      if (navigationState === NavigationState.minimized) {
        return 'content-minimized'
      }

      return 'content-default'
    }
  }
