










import { Component, Vue, Watch } from 'vue-property-decorator'
import { CompanyActions } from '@/shared/store/company/company.actions'
import CompanyModel from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import NotFound from '@/shared/components/NotFound.vue'
import CompanyLayout from '@/company/layouts/CompanyLayout.vue'
import User from '@/shared/modules/user/user.model'
import { GlobalActions } from '@/shared/store/global/global.actions'
import PendingMoves from '@/company/components/tools/PendingMoves.vue'
import Echo from 'laravel-echo'
import { GlobalGetters } from '@/shared/store/global/global.getters'
import { AuthActions } from '@/shared/store/auth/auth.actions'

@Component({
  components: {
    CompanyLayout,
    NotFound
  }
})
export default class Company extends Vue {
  @Watch('$store.state.auth.user.pendingToolMoves', { deep: true, immediate: true }) watchUser(): void {
    this.$store.dispatch(GlobalActions.closeDialog)

    setTimeout(() => {
      if (this.user && this.user.pendingToolMoves?.length > 0) {
        this.$store.dispatch(GlobalActions.showDialog, {
          show: true,
          persistent: true,
          noClose: true,
          component: PendingMoves,
          maxWidth: 670,
          meta: {
            pendingToolMoves: this.user?.pendingToolMoves,
          }
        })
      }
    })
  }
  private channel: string = ''

  created(): void {
    this.registerEchoServer()
  }

  mounted() {
    const action = this.$store.getters[AuthGetters.isLogged] ? CompanyActions.loadCurrentCompany : CompanyActions.loadStrictCompany
    this.$store.dispatch(action, this.$router.currentRoute.params.company)
  }

  private registerEchoServer(): void {
    if (! this.user) return

    this.channel = `users.${ this.user.uuid }`

    this.echoInstance
      .private(this.channel)
      .listen('.pending-moves', () => {
        this.$store.dispatch(AuthActions.me)
      })
  }

  get echoInstance(): Echo {
    return this.$store.getters[GlobalGetters.getEchoServerInstance]
  }

  get companyLoading(): boolean {
    return this.$store.getters[CompanyGetters.getLoading]
  }

  get company(): CompanyModel | null {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get user(): User | null {
    return this.$store.getters[AuthGetters.getUser]
  }
}
