





import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import Form from '@/shared/components/form/Form.vue'
import PendingToolMove from '@/shared/modules/pending-tool-move/pending-tool-move.model'
import { FieldTypes } from '@/shared/components/form/field-types'
import PendingMoveToolFilesDialog from '@/company/components/tools/PendingMoveToolFilesDialog.vue'

@Component({
  components: { Form }
})
export default class PendingMoveItemExpanded extends Vue {
  @Prop() item!: PendingToolMove
  @Prop() meta!: {
    updateItemComment: (item: PendingToolMove, value: string) => void,
    updateItemPhotos: (item: PendingToolMove, value: any[]) => void,
  }
  @Watch('form.data.photos', { deep: true, immediate: false }) private onPhotosChange() {
    this.meta.updateItemPhotos && this.meta.updateItemPhotos(this.item, this.form.data.photos)
  }

  form: FormBase = new FormBase()
    .setFields([
      new Field()
        .setKey('comment')
        .setTitle('Komentaras')
        .setDense(true)
        .setSize(FieldSizes.elevenTwelfth)
        .setOnChange((value: any) => this.meta.updateItemComment(this.item, value)),
      new Field()
        .setType(FieldTypes.custom)
        .setKey('photos')
        .setComponent(PendingMoveToolFilesDialog)
        .setValue([])
        .setSize(FieldSizes.oneTwelfth)
    ])
    .setInitialValues({
      comment: '',
      photos: [],
    })
    .setSubmit(false)
}
