


























import { Component, Prop, Vue } from 'vue-property-decorator'
import Field from '@/shared/classes/form/field'
import FormBase from '@/shared/classes/form/form-base'
import ArrayField from '@/shared/classes/form/fields/array-field'
import { FieldTypes } from '@/shared/components/form/field-types'
import __ from '@/shared/helpers/__'
import AddMultiplePhotosButton from '@/company/components/task-types/AddMultiplePhotosButton.vue'
import Form from '@/shared/components/form/Form.vue'
import _ from 'lodash'

@Component({
  components: { Form },
  methods: { __ }
})
export default class PendingMoveToolFilesDialog extends Vue {
  @Prop() field!: Field
  @Prop() form!: FormBase
  @Prop() fullKey!: string
  dialog: boolean = false

  photosForm: FormBase = new FormBase()
    .setFields([
      new ArrayField()
        .setKey('photos')
        .setTitle('Nuotraukos')
        .setDense(true)
        .setChildren([
          new Field()
            .setType(FieldTypes.file)
            .setKey('file')
            .setDense(true)
            .setTitle(__('company.components.task-types.task-type-files-dialog.form.file'))
            .setValue([])
            .setMeta({
              type: 'image',
              accept: 'image/*',
            }),
        ])
        .setAddButton(() => false)
        .setAdditionalActions([
          AddMultiplePhotosButton
        ])
    ])
    .setOnSuccess((data: any) => {
      _.set(this.form.data, this.fullKey, data.photos.filter((photo: any) => {
        if (photo.file) {
          if (photo.file.length !== 0) return photo
        }
      }))

      this.onClose()
    })

  openDialog() {
    this.photosForm.setData({
      photos: _.get(this.form.data, this.fullKey, [])
    })

    this.dialog = true
  }

  onClose() {
    this.dialog = false
  }

  get badgeCount(): number {
    return this.form.data.photos.length
  }
}
