























import { Component, Vue } from 'vue-property-decorator'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import UserAvatar from '@/shared/components/layout/UserAvatar.vue'
import tokenHelper from '@/shared/helpers/token.helper'
import { IOtherAccessToken } from '@/shared/interfaces/helpers/token.interface'
import router, { Routes } from '@/shared/router'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { RoleLevel } from '@/shared/modules/role/role.model'
import __ from '@/shared/helpers/__'

@Component({
  components: { UserAvatar },
  methods: { __ }
})
export default class LoginLikeUserControl extends Vue {
  goIn(user: User): void {
    const otherAccessToken = tokenHelper.getOtherAccessTokens().find((token: IOtherAccessToken) => token.userUuid === user.uuid)
    if (! otherAccessToken) return

    if (user.role.level === RoleLevel.superadmin) {
      tokenHelper.resetOtherAccessTokens()
    } else {
      tokenHelper.removeOtherAccessToken(user.uuid)
    }

    tokenHelper.setToken(otherAccessToken.authToken)
    this.$store.dispatch(GlobalActions.closeDialog)
    router.push({ name: Routes.redirection }).catch(() => {})
  }

  get user(): User | null {
    return this.$store.getters[AuthGetters.getUser]
  }

  get otherUsers(): User[] {
    return this.$store.getters[AuthGetters.getOtherUsers]
  }
}
